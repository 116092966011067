"use client";
import { useEffect, lazy, Suspense, useState } from "react";
import MainScreen from "../mainScreen/MainScreen";
import { AppHeader } from "../appHeader/AppHeader";
import { AppFooter } from "../appFooter/AppFooter";

import Lenis from "lenis";
import gsap from "gsap";

window.gsap = gsap;

const FeaturedWorks = lazy(() => import("../featuredWorks/FeaturedWorks"));
const ThatLooksGood = lazy(() => import("../thatLooksGood/ThatLooksGood"));
const WhoRiddle = lazy(() => import("../whoRiddle/WhoRiddle"));
const TeachTok = lazy(() => import("../teachTok/TeachTok"));
const OurServices = lazy(() => import("../ourServices/OurServices"));
const OurTechnologies = lazy(
  () => import("../ourTechnologies/OurTechnologies")
);
const CaseStudies = lazy(() => import("../caseStudies/CaseStudies"));
const PopDoc = lazy(() => import("../popDoc/PopDoc"));

const App: React.FC = () => {
  const [shouldLoadContent, setShouldLoadContent] = useState(false);
  const [lazyComponentsLoaded, setLazyComponentsLoaded] = useState(false);

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.7,
      // @ts-ignore
      smooth: true,
      touchMultiplier: 0,
    });

    function raf(time: any) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, []);

  useEffect(() => {
    const restoreScrollPosition = () => {
      const savedScrollPosition = localStorage.getItem("scrollPosition");
      if (savedScrollPosition && lazyComponentsLoaded) {
        setTimeout(() => {
          window.scrollTo(0, parseInt(savedScrollPosition, 10));
        }, 0);
      }
    };

    // Check localStorage for lazy-load state
    const savedState = localStorage.getItem("shouldLoadContent");
    if (savedState === "true") {
      setShouldLoadContent(true);
    }

    // Restore scroll position after components are loaded
    restoreScrollPosition();
  }, [lazyComponentsLoaded]);

  useEffect(() => {
    const saveScrollPosition = () => {
      localStorage.setItem("scrollPosition", window.scrollY.toString());
    };

    const handleUserScroll = () => {
      if (!shouldLoadContent && window.scrollY > 0) {
        setShouldLoadContent(true);
        localStorage.setItem("shouldLoadContent", "true");
      }
    };

    const clearLocalStorage = () => {
      let session = sessionStorage.getItem("register");

      if (session == null) {
        // localStorage.removeItem("scrollPosition");
        localStorage.removeItem("shouldLoadContent");
      }
    };

    window.addEventListener("scroll", handleUserScroll);
    window.addEventListener("beforeunload", saveScrollPosition);
    window.addEventListener("unload", clearLocalStorage);

    return () => {
      window.removeEventListener("scroll", handleUserScroll);
      window.removeEventListener("beforeunload", saveScrollPosition);
      window.removeEventListener("unload", clearLocalStorage);
    };
  }, [shouldLoadContent]);

  return (
    <>
      <MainScreen />
      <AppHeader />
      <FeaturedWorks />
      <div
        id="lazy-load-trigger"
        style={{ height: "1px", background: "transparent" }}
      />
      <ThatLooksGood />
      {shouldLoadContent && (
        <Suspense fallback={<div>Loading...</div>}>
          <LazyContent
            onLazyLoadComplete={() => setLazyComponentsLoaded(true)}
          />
        </Suspense>
      )}
    </>
  );
};

const LazyContent: React.FC<{ onLazyLoadComplete: () => void }> = ({
  onLazyLoadComplete,
}) => {
  useEffect(() => {
    // Trigger callback once this component is mounted (all lazy components are loaded)
    onLazyLoadComplete();
  }, [onLazyLoadComplete]);

  return (
    <>
      <WhoRiddle />
      <TeachTok />
      <PopDoc />
      <OurServices />
      <OurTechnologies />
      <CaseStudies />
      <AppFooter />
    </>
  );
};

export default App;
