const fontFaces = `
  @font-face {
    font-family: "SF Pro Display Light";
    font-style: normal;
    font-display: block;
    src: url("https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/raw/upload/v1731337184/SFProDisplay-Light_bulray.woff2") format("woff2");
  }

  @font-face {
    font-family: "SF Pro Display Regular";
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: url("https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/raw/upload/v1731209488/SFProDisplay-Regular_nol8p2.woff2") format("woff2");
  }

  @font-face {
    font-family: "SF Pro Display Medium";
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: url("https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/raw/upload/v1731209488/SFProDisplay-Medium_wempir.woff2") format("woff2");
  }

  @font-face {
    font-family: "SF Pro Text Light";
    font-style: normal;
    font-weight: bold;
    font-display: block;
    src: url("https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/raw/upload/v1731337470/SFProText-Light_iucajg.woff2") format("woff2");
  }

  @font-face {
    font-family: "SF Pro Text Regular";
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: url("https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/raw/upload/q_auto,f_auto/v1731209383/SFProText-Regular_k8xwx8.woff2") format("woff2");
  }
`;

const style = document.createElement('style');
style.textContent = fontFaces;
document.head.appendChild(style); 