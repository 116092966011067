import { Cloudinary } from "@cloudinary/url-gen";
import { memo, Suspense, useEffect, useRef } from "react";
import { AdvancedVideo } from "@cloudinary/react";

const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
  }
});
interface VideoPlayerProps {
    src: string;
    controls?: boolean;
    autoPlay?: boolean;
    loop?: boolean;
    playsInline?: boolean;
    className?: string;
    muted?: boolean;
    fetchpriority?: 'high' | 'low' | 'auto';
   }

const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
};
export const VideoPlayer:  React.FC<VideoPlayerProps> = memo(({className, src, autoPlay = true, loop = true, playsInline = true, controls = false, muted = true, fetchpriority = 'low'}) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        if (!isMobileDevice() || !autoPlay) {
            return;
        }

        const handleWindowFocus = () => {
            if (videoRef.current) {
                videoRef.current.play().catch(() => {
                    console.warn("Unable to play the video automatically.");
                });
            }
        };

        const handleWindowBlur = () => {
            if (videoRef.current) {
                videoRef.current.pause();
            }
        };

        window.addEventListener("focus", handleWindowFocus);
        window.addEventListener("blur", handleWindowBlur);

        return () => {
            window.removeEventListener("focus", handleWindowFocus);
            window.removeEventListener("blur", handleWindowBlur);
        };
    }, []);

    const videoUrl = cld.video(src)
    .format('auto')
    .quality('auto')

    const posterUrl = cld.video(src)
    .format('auto')
    .quality('auto')
    .addTransformation('so_0')
    .toURL();

return (
    <Suspense fallback={<div>Loading video...</div>}>
    <AdvancedVideo
        cldVid={videoUrl}
        className={className}
        autoPlay={autoPlay}
        loop={loop}
        playsInline={playsInline}
        controls={controls}
        poster={posterUrl}
        muted={muted}
        fetchpriority={fetchpriority}
        innerRef={videoRef}
    />
    </Suspense>
   );
});
